<template>
  <div class="NotificationsVoice" v-if="user.subscription_plan.integration_twilio_signl4">
    <div class="top">
      <div class="title">Voice calls notifications</div>
    </div>
    <div class="card">
      <div class="card-body">
        <voice-notifications :monitor="monitor"
                             :general="general"
                             @update="$emit('update')"/>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="upgrade">
      <UpgradeBadge text="Twilio voice calls are available only for paid customers."/>
    </div>
  </div>
</template>
<script>
import VoiceNotifications from '@/components/Monitor/Notifications/Voice/Voice.vue'
import UpgradeBadge from '@/components/Common/UpgradeBadge'
export default {
  components: {
    VoiceNotifications,
    UpgradeBadge
  },

  metaInfo: {
    title: 'Voice Calls Notifications'
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  computed: {
    ...mapState('authentication', ['user'])
  }

}
</script>

<style lang="scss" scoped>
  .NotificationsVoice {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .title {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.5;
        color: #444;
      }
    }
  }
  .upgrade {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    @media (max-width: 767px) {
      margin: 24px 0;
      min-height: unset !important;
    }
  }
</style>
