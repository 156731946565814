<template>
  <div class="ConnectToTwilioForm">
    <ValidationObserver ref="observer" v-slot="{ invalid, passes }">
      <form ref="form" @submit.prevent="passes(onFormSubmit)">
        <base-textbox label="Account SID"
                      rules="required"
                      v-model="twilio.sid" />

        <base-textbox label="Auth Token"
                      rules="required"
                      v-model="twilio.token" />

        <base-textbox label="Phone Number"
                      rules="required"
                      v-model="twilio.phone_number" />

        <base-button type="submit"
                     ref="submit"
                     color="primary"
                     :loading="submitting">Connect Twilio</base-button>
      </form>
    </ValidationObserver>

    <div v-show="false">
      <hint-twilio-connection-failed ref="hint" />
    </div>
  </div>
</template>

<script>
import profileApi from '@/api/profileApi.js'
import tippy from 'tippy.js'

import HintTwilioConnectionFailed from '@/components/Hints/HintTwilioConnectionFailed.vue'

export default {
  components: {
    HintTwilioConnectionFailed
  },

  props: {
    user: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      twilio: {
        sid: this.user.twilio_account_sid,
        token: this.user.twilio_auth_token,
        phone_number: this.user.twilio_phone_number
      },

      submitting: false,
      tippy: null
    }
  },

  methods: {
    async onFormSubmit () {
      this.submitting = true

      const successful = await profileApi.connectToTwilio(this.twilio)

      this.submitting = false

      if (!successful) {
        this.showTippy()
      } else {
        this.$emit('connect')
      }
    },

    showTippy () {
      if (this.tippy) {
        this.tippy.destroy()
      }

      this.tippy = tippy(this.$refs.submit.$el, {
        allowHTML: true,
        content: this.$refs.hint.$el,
        interactive: true,
        arrow: false,
        placement: 'right-end',
        trigger: 'manual',
        theme: 'big-padding',
        animation: 'shift-away-extreme'
      })

      this.tippy.show()
    }
  }
}
</script>

<style lang="scss" scoped>
  .ConnectToTwilioForm {

  }
</style>
