<template>
  <div class="HintTwilioConnectionFailed">
    <p class="title mb-2">Twilio connection failed</p>
    <p class="mb-2">
      For some reason we could not connect to Twilio using provided credentials.
      Please check if they are correct and try again.
    </p>
    <p>
      If you need any help, please drop a line to {{ supportEmail }}
    </p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL
    }
  }
}
</script>

<style lang="scss" scoped>
  .HintTwilioConnectionFailed {
    line-height: 1.5;

    .title {
      font-weight: bold;
    }
  }
</style>
