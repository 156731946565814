<template>
  <div class="VoicePhoneNumbers">
    <div v-if="general">
      <div class="description">
        You can specify up to 5 phone numbers to which we will send SMS notifications
        in case of something went wrong with your monitors.
      </div>
      <base-alert type="error" v-if="generalError && generalHyperlink">
        You have reached the phone numbers limit on monitor: <a :href=generalHyperlink>{{generalError}}</a>
      </base-alert>
      <base-alert type="error" v-else-if="generalError">
        {{generalError}}
      </base-alert>
        <div class="phone-number"
             v-for="(channel, index) in userGeneralNotificationChannels"
             :key="index">

          <div class="remove"
               @click.prevent="deleteGeneralChannel(channel)">
            <icon-trash width="18"
                        height="18" />
          </div>

          <div class="text">
            {{ channel.item_value }}
          </div>
        </div>
      <new-number :general="general"
                  @add="loadGeneralNotificationChannels"
                  @error="voiceAddError"/>
    </div>
    <div v-else>
    <div class="description">
      You can specify up to 5 phone numbers to which we will make voice calls notifications
      in case of something went wrong with
      <monitor-label :monitor="monitor"
                     :show-favicon="false" />.
      <br>
      You have a total of 3 tests per day.
      <div v-bind:class="{ 'text-danger': user.tests_per_day < 1 }">Remaining tests {{user.tests_per_day}}.</div>
    </div>
    <div class="phone-numbers">
      <div class="phone-number"
           v-for="(channel, index) in channels"
           :key="index">

        <div class="remove"
             @click.prevent="removePhoneNumber(channel)">
          <icon-trash width="18"
                      height="18" />
        </div>

        <div class="text">
          {{ channel.item_value }}
        </div>

        <base-button v-if="user.tests_per_day > 0"
                     class="ml-auto"
                     color="primary"
                     :loading="testingVoice"
                     @click="testVoicePhoneNumber(channel.item_value)">Test voice call</base-button>
      </div>
      <base-alert v-if="testingVoiceError" class="mt-4"
                  type="error">{{testingVoiceError}}</base-alert>
    </div>

    <new-number v-if="channels.length < 5"
                :monitor="monitor"
                :general="general"
                @add="onNumberAdd" />
    </div>
  </div>
</template>
<script>
import MonitorLabel from '@/components/Monitor/MonitorLabel.vue'
import NewNumber from './VoicePhoneNumbersNew.vue'
import voiceApi from '@/api/monitorNotifications/voiceApi'
import notificationChannelsApi from '@/api/monitorNotifications/notificationChannelsApi'
import usersApi from '../../../../api/usersApi'

export default {
  components: {
    MonitorLabel,
    NewNumber
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      channels: [],
      saving: false,
      testingVoice: false,
      testingVoiceError: false,
      userGeneralNotificationChannels: null,
      generalError: false,
      generalHyperlink: false
    }
  },

  created () {
    if (this.general) {
      this.loadGeneralNotificationChannels()
    }
  },

  methods: {
    onNumberAdd () {
      this.$emit('update')
    },

    async removePhoneNumber (channel) {
      await notificationChannelsApi.delete(channel.id)
      this.$emit('update')
    },

    async deleteGeneralChannel (channel) {
      await notificationChannelsApi.generalDelete(channel.id)
      await this.loadGeneralNotificationChannels()
    },

    async loadGeneralNotificationChannels () {
      try {
        this.userGeneralNotificationChannels = await usersApi.getGeneralChannels('twilio_voice')
      } catch (e) {
        this.generalError = 'Something went wrong'
      }
    },

    async testVoicePhoneNumber (phoneNumber) {
      this.testingVoice = true
      this.testingVoiceError = false
      try {
        await voiceApi.testVoice(this.monitor.id, phoneNumber)
      } catch (error) {
        this.testingVoiceError = error.response.data.message ?? 'We could not send the sms.'
      }
      await this.refreshCurrentUser()
      this.testingVoice = false
    },

    async voiceAddError (value) {
      this.generalError = value.errorMonitor
      this.generalHyperlink = value.hyperlink
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    ...mapState('authentication', ['user'])
  },

  watch: {
    monitor: {
      handler: function () {
        if (!this.general) {
          this.channels = this.monitor.notification_channels
            .filter(channel => channel.via === 'twilio_voice')
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .VoicePhoneNumbers {
    max-width: 550px;

    .description {
      margin-bottom: 10px;
      color: #777;
      line-height: 1.5;

      .text-danger {
        color: #ED4F32;
      }
    }

    .phone-numbers {
      max-width: 434px;
      margin-bottom: 10px;
    }

    .phone-number {
      display: flex;
      align-items: center;

      .remove {
        cursor: pointer;
        padding: 10px 10px 10px 0;

        &:hover /deep/ svg g {
          transition: 0.2s all ease;
          fill: map-get($colors, purple-1) !important;
        }
      }

      .text {
        margin-bottom: 3px;
      }
    }
  }
</style>
